<template>
  <div class="main_v8">
      <h1>User Management</h1>
      <a class="btn-edit" @click='addUser()'>Add User</a>
      <a class="btn-edit" @click='test()'>Test</a>
      <br><br>
      <table class="blueTable">
        <thead><tr><th>User</th><th>Created</th><th>Actions</th></tr></thead>
        <tbody>
          <tr v-for = "user in users" >
            <td>{{ user.user.email }}</td>
            <td>{{ timeConverter(user.user.timeJoined) }}</td>
            <td>
                <a class="btn-edit" @click='getUserMetadata(user.user.id)'>Edit</a>
                <a class="btn-reset" @click='sendEmailClicked(user.user.email)'>Reset_Password</a>
                <a class="btn-delete" @click='deleteUser(user.user.id)'>Delete</a>
              </td>
          </tr>
        </tbody>
    </table>
    <br><br>
    &nbsp;&nbsp;&nbsp;&nbsp;metadata:
    <div class="box"> {{ md }} </div>
</div>
</template>

<script setup>
  import { ref } from 'vue';
  import EmailPassword from 'supertokens-web-js/recipe/emailpassword';
  let users = ref([]);
  let md = ref([]);
  loadUsers();

  function loadUsers() {
    fetch('https://auth.wave7.cloud/auth/getUsers', {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        'Accept':'application/json'
      }
    }).then((res) => res.json()).then((r) => {
      users.value = r.users;
      console.log("users",r);
    }).catch((error) => {
      console.log('Looks like there was a problem with fetch on User Management: \n', error);
    });
  }

  function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp);
    var year = a.getFullYear();
    var month = a.getMonth() + 1;
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = year + '-' + month.toString().padStart(2,'0') + '-' + date.toString().padStart(2,'0') + ' ' + hour.toString().padStart(2,'0') + ':' + min.toString().padStart(2,'0');
    return time;
  }

  function getUserMetadata(id) {
    md.value = "Loading...";
    fetch(`https://auth.wave7.cloud/auth/userinfobyid`, {
      method: 'POST',
      headers: {
            'Content-Type':'application/json',
            'Accept':'application/json'
          },
      body: JSON.stringify({"userid":id})
    }).then((res) => res.json()).then((response) => {
      md.value = response.metadata;
      console.log("USERS METADATA:",md.value);
    }).catch((error) => {
      console.log('Looks like there was a problem with ya: \n', error);
    });
  }

  function deleteUser(id) {
    const pin = Math.floor(1000 + Math.random() * 9000);
    let ch = prompt("Are you sure that you want to delete this user?  Please type " + pin.toString() + " to confirm ");
    if(ch==pin)
    {
      fetch(`https://auth.wave7.cloud/auth/deleteUser`, {
        method: 'POST',
        headers: {
              'Content-Type':'application/json',
              'Accept':'application/json'
            },
        body: JSON.stringify({"userid":id})
      }).then((res) => res.json()).then((response) => {
        console.log("DELETE-USER",response)
        alert("User successfully DELETED!");
        loadUsers();
      }).catch((error) => {
        console.log('Looks like there was a problem with ya: \n', error);
      });
    }
  }

  function randomPassword() {
    return Math.random().toString(36).slice(2) +
        Math.random().toString(36)
        .toUpperCase().slice(2);
}

  async function sendEmailClicked(email) {
      try {
        let response = await EmailPassword.sendPasswordResetEmail({
          formFields: [{
            id: "email",
            value: email
          }]
        });

        if (response.status === "FIELD_ERROR") {
          response.formFields.forEach(formField => {
            if (formField.id === "email") {
              console.log(formField.error);
            }
          })
        } else {
          window.alert("Please check your email for the password reset link")
        }
      } catch (err) {
        if (err.isSuperTokensGeneralError === true) {
          window.alert(err.message);
        } else {
          window.alert("Oops! Something went wrong.");
        }
      }
    }

    async function addUser() {
      
        //const email = "jconnor34@gmail.com";
        let email = prompt("Please enter your email", "");

        if (email != null) {
        try {
          let response = await EmailPassword.signUp({
              formFields: [
              {
                id: "email",
                value: email
              },
              {
                id: "password",
                value: randomPassword()
              },
            ]
            });

            if (response.status === "FIELD_ERROR") {
              response.formFields.forEach(formField => {
                if (formField.id === "email") {
                  console.log("FORMFIELDERROR",formField.error);
                }
              })
            } else {
              loadUsers();
            }
          } catch (err) {
            console.log("ERRRRORRR:",err);
            if (err.isSuperTokensGeneralError === true) {
              window.alert(err.message);
            } else {
              window.alert("Oops! Something went wrong.");
            }
          }
        }
        
    }

  async function fetchAuthPost(link,data = {}) {
    return await fetch(`https://auth.wave7.cloud${link}`, {
      method: 'POST',
      headers: {
        'Content-Type':'application/json',
        'Accept':'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => res.json()).then((response) => {
      return response;
    }).catch((error) => {
      console.log('Looks like there was a problem with fetch on User Management: \n', error);
      return false;
    });
  }
  async function test() {
    // // add role
    // fetchAuthPost('/data/addRole',{"role" : "user"}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // // delete role
    // fetchAuthPost('/data/deleteRole',{"role" : "user"}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // // get all roles
    // fetchAuthPost('/data/getRoles').then((response) => {
    //   console.log('check', response);
    // });
    //
    // // get current user roles
    // fetchAuthPost('/data/getUserRoles',{"userId":userId}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // // link user to the role
    // fetchAuthPost('/data/addUserRole',{"role":"user","userId":"ef5d4166-0d77-412a-91c4-23a384a985fd"}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // // unlink user from the role
    // fetchAuthPost('/data/deleteUserRole',{"role":"user","userId":"ef5d4166-0d77-412a-91c4-23a384a985fd"}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // add tenant
    // fetchAuthPost('/data/addTenant',{"tenant" : "test"}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // // link user to the tenant
    // fetchAuthPost('/data/addUserToTenant',{"tenant":"test","userId":"ef5d4166-0d77-412a-91c4-23a384a985fd"}).then((response) => {
    //   console.log('check', response);
    // });
    //
    // // unlink user from the tenant
    // fetchAuthPost('/data/removeUserFromTenant',{"tenant":"test","userId":"ef5d4166-0d77-412a-91c4-23a384a985fd"}).then((response) => {
    //   console.log('check', response);
    // });

  }
</script>

<style lang="scss" scoped>
  .main{
      padding-top: 60px;
  }

  .box{
    border:#444444 solid 1px;
    padding:10px;
    margin-left:20px;
    width: 90%;
    max-width: 1920px;
  }

  table.blueTable {
    border: 1px solid #1C6EA4;
    background-color: #EEEEEE;
    margin-left:20px;
    width: 90%;
    max-width: 1920px;
    text-align: left;
    border-collapse: collapse;
  }
  table.blueTable td, table.blueTable th {
    border: 1px solid #AAAAAA;
    padding: 3px 2px;
  }
  table.blueTable tbody td {
    font-size: 17px;
    padding:20px;
  }
  table.blueTable tr:nth-child(even) {
    background: #D0E4F5;
  }
  table.blueTable thead {
    background: #1C6EA4;
    background: -moz-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    background: -webkit-linear-gradient(top, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    background: linear-gradient(to bottom, #5592bb 0%, #327cad 66%, #1C6EA4 100%);
    border-bottom: 2px solid #444444;
  }
  table.blueTable thead th {
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    border-left: 2px solid #D0E4F5;
    padding:10px;
  }
  table.blueTable thead th:first-child {
    border-left: none;
  }

  table.blueTable tfoot {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    background: #D0E4F5;
    background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #D0E4F5 100%);
    border-top: 2px solid #444444;
  }
  table.blueTable tfoot td {
    font-size: 14px;
  }
  table.blueTable tfoot .links {
    text-align: right;
  }
  table.blueTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }

  .btn-edit {
    background: #157402;
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  margin:5px;
  text-decoration: none;
}

.btn-edit:hover {
  background: #3cb0fd;
  text-decoration: none;
}

.btn-reset {
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  background: #3498db;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin:5px;
}

.btn-reset:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}

.btn-delete {
  -webkit-border-radius: 10;
  -moz-border-radius: 10;
  border-radius: 10px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  background: #fa0808;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  margin:5px;
}

.btn-delete:hover {
  background: #3cb0fd;
  background-image: -webkit-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -moz-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -ms-linear-gradient(top, #3cb0fd, #3498db);
  background-image: -o-linear-gradient(top, #3cb0fd, #3498db);
  background-image: linear-gradient(to bottom, #3cb0fd, #3498db);
  text-decoration: none;
}

</style>
